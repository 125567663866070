import type {
  EditorEpisode,
  EditorSeries,
  MobileEpisode,
} from "@/src/constants/types/seriesTypes";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  or,
  query,
  where,
} from "firebase/firestore";
import editorDatabase, {
  EPISODES_COLLECTION,
  SERIES_COLLECTION,
} from "./firebaseClientConfig";
import mobileDatabase, { MOBILE_EPISODES_COLLECTION } from "./mobileFirebase";

export const fetchAllSeries = async (): Promise<EditorSeries[]> => {
  const seriesQuery = query(collection(editorDatabase, SERIES_COLLECTION));

  const seriesSnapshot = await getDocs(seriesQuery);

  return seriesSnapshot.docs
    .map((doc) => {
      return doc.data();
    })
    .sort((doc1, doc2) => {
      return doc1.createdAt - doc2.createdAt;
    }) as EditorSeries[];
};

export const fetchAllUserSeries = async (
  userId: string,
): Promise<EditorSeries[]> => {
  const seriesQuery = query(
    collection(editorDatabase, SERIES_COLLECTION),
    or(
      where("userId", "==", userId),
      where("collaboratorIds", "array-contains", userId),
      where("creatorIds", "array-contains", userId),
    ),
  );

  const seriesSnapshot = await getDocs(seriesQuery);

  return seriesSnapshot.docs
    .map((doc) => {
      return doc.data();
    })
    .sort((doc1, doc2) => {
      return doc1.createdAt - doc2.createdAt;
    }) as EditorSeries[];
};

export const fetchAllUserSeriesCollaborations = async (userId: string) => {
  const seriesQuery = query(
    collection(editorDatabase, SERIES_COLLECTION),
    where("collaboratorIds", "array-contains", userId),
  );

  const seriesSnapshot = await getDocs(seriesQuery);

  return seriesSnapshot.docs
    .map((doc) => {
      return doc.data();
    })
    .sort((doc1, doc2) => {
      return doc1.createdAt - doc2.createdAt;
    }) as EditorSeries[];
};

export const fetchAllUserSeriesCreations = async (userId: string) => {
  const seriesQuery = query(
    collection(editorDatabase, SERIES_COLLECTION),
    where("creatorIds", "array-contains", userId),
  );

  const seriesSnapshot = await getDocs(seriesQuery);

  return seriesSnapshot.docs
    .map((doc) => {
      return doc.data();
    })
    .sort((doc1, doc2) => {
      return doc1.createdAt - doc2.createdAt;
    }) as EditorSeries[];
};

export const fetchAllUserSeriesCollaborationsAndCreations = async (
  userId: string,
) => {
  const [userCollaborations, userCreations] = await Promise.all([
    fetchAllUserSeriesCollaborations(userId),
    fetchAllUserSeriesCreations(userId),
  ]);

  const allSeries = [...userCollaborations, ...userCreations];

  return [...new Set(allSeries.map((doc) => doc.id))]
    .map((docId) => {
      const seriesDoc = allSeries.find((doc) => doc.id === docId);
      if (!seriesDoc) throw new Error("Series not found");
      return seriesDoc;
    })
    .sort((doc1, doc2) => {
      if (!doc1 || !doc2) return 0;
      return (
        doc1.createdAt.toDate().getTime() - doc2.createdAt.toDate().getTime()
      );
    });
};

export const fetchAllUserSeriesAndCollaborationsAndCreations = async (
  userId: string,
) => {
  const [userSeries, userCollaborations, userCreations] = await Promise.all([
    fetchAllUserSeries(userId),
    fetchAllUserSeriesCollaborations(userId),
    fetchAllUserSeriesCreations(userId),
  ]);

  const allSeries = [...userSeries, ...userCollaborations, ...userCreations];

  return [...new Set(allSeries.map((doc) => doc.id))]
    .map((docId) => {
      const seriesDoc = allSeries.find((doc) => doc.id === docId);
      if (!seriesDoc) throw new Error("Series not found");
      return seriesDoc;
    })
    .sort((doc1, doc2) => {
      if (!doc1 || !doc2) return 0;
      return (
        doc1.createdAt.toDate().getTime() - doc2.createdAt.toDate().getTime()
      );
    });
};

export const fetchEpisodesBySeriesId = async (
  seriesId: string,
): Promise<EditorEpisode[]> => {
  const seriesQuery = query(
    collection(editorDatabase, EPISODES_COLLECTION),
    where("seriesId", "==", seriesId),
  );

  const seriesSnapshot = await getDocs(seriesQuery);

  return seriesSnapshot.docs
    .map((doc) => {
      const data = doc.data();
      return {
        coverURL: data.coverURL,
        createdAt: data.createdAt,
        id: data.id,
        name: data.name,
        order: data.order,
        regeneratedAt: data.regeneratedAt,
        readyToPublish: data.readyToPublish,
        seriesId: data.seriesId,
        updatedAt: data.updatedAt,
        userId: data.userId,
      } as EditorEpisode;
    })
    .sort((doc1, doc2) => {
      return doc1.order - doc2.order;
    });
};

export const fetchMobileEpisode = async (
  episodeId: string,
): Promise<MobileEpisode | null> => {
  const comicEpisodeDoc = doc(
    collection(mobileDatabase, MOBILE_EPISODES_COLLECTION),
    episodeId,
  );

  const comicEpisodeSnapshot = await getDoc(comicEpisodeDoc);

  if (!comicEpisodeSnapshot.exists()) {
    return null;
  }

  const data = comicEpisodeSnapshot.data();

  return {
    comic_id: data.comic_id,
    cover: data.cover,
    created_at: data.created_at,
    id: data.id,
    name: data.name,
    order: data.order,
    pages: data.pages,
    publish_at: data.publish_at,
  } as MobileEpisode;
};
