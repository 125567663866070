"use client";

import { SERIES_PATH } from "@/src/constants/route";
import {
	EditorSeries,
	MobileSeries,
} from "@/src/constants/types/seriesTypes";
import { useFirestoreCollection } from "@/src/hooks/useFirestoreCollection";
import { useAuth } from "@/src/providers/AuthProvider";
import {
	deleteSeries,
	syncMobileSeriesToEditor,
} from "@/src/utils/firebase/desktopMobileSeriesOperations";
import { storage } from "@/src/utils/firebase/firebaseClientConfig";
import mobileDatabase, {
	MOBILE_LIBRARY_COLLECTION,
} from "@/src/utils/firebase/mobileFirebase";
import { User } from "@/src/utils/firebase/userOperations";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
	createTheme,
	Step,
	StepLabel,
	Stepper,
	ThemeProvider,
} from "@mui/material";
import {
	Timestamp,
	collection,
	doc,
	getDoc,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useState } from "react";
import slugify from "slugify";
import SeriesUserAddModal from "./SeriesUserAddModal";
import { CircularProgress } from "@mui/material";
import { toast } from "sonner";
import { Button } from "@/src/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

export enum SeriesPublishStep {
	CREATE = "Create",
	COMING_SOON = "Coming Soon",
	PUBLISH = "Publish",
}

export const seriesPublishSteps = [
	SeriesPublishStep.CREATE,
	SeriesPublishStep.COMING_SOON,
	SeriesPublishStep.PUBLISH,
];

const SeriesForm = ({
	series,
	mutate,
}: {
	series: EditorSeries;
	mutate: (series: Partial<EditorSeries>) => Promise<void>;
}) => {
	const [isSectionExpanded, setIsSectionExpanded] = useState(false);
	const [addTagsOpen, setAddTagsOpen] = useState(false);
	const [freeEpisodesCount, setFreeEpisodesCount] = useState(
		series.freeEpisodesCount,
	);
	const [creatorModalOpen, setCreatorModalOpen] = useState(false);
	const [collaboratorModalOpen, setCollaboratorModalOpen] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);

	const {
		bannerURL,
		coverURL,
		fullScreenCoverURL,
		name,
		tags,
		description,
		tagline,
		collaboratorIds,
		creatorIds,
		userId,
		id,
		updatedAt,
		createdAt,
		publishStep,
	} = series;

	const { user, isAdmin } = useAuth();
	const router = useRouter();

	const { status, data: allTags } = useFirestoreCollection<{
		name: string;
		id: string;
	}>("comicSeriesTags");

	const { status: usersStatus, data: users } = useFirestoreCollection<User>(
		"users",
		[where("uid", "in", [...creatorIds, ...collaboratorIds])],
	);

	type ButtonConfig = {
		text: string;
		onClick: () => void;
		className?: string;
	};

	const handleDelete = async () => {
		await deleteSeries({
			id,
		});

		router.replace(SERIES_PATH);
	};

	const getLeftButton = (publishStep: SeriesPublishStep): ButtonConfig => {
		switch (publishStep) {
			case SeriesPublishStep.CREATE:
				return {
					text: "Delete",
					onClick: () => {
						if (
							confirm(
								"Are you sure you want to delete this series, along with all of its episodes?",
							)
						) {
							handleDelete();
						}
					},
					className: "text-black bg-[#F65757] hover:bg-[#FFBFBF]",
				};
			case SeriesPublishStep.COMING_SOON:
			case SeriesPublishStep.PUBLISH:
				return {
					text: "Unpublish",
					onClick: async () => {
						if (confirm("Are you sure you want to unpublish this series?")) {
							const syncSuccess = await syncMobileSeriesToEditor(
								series,
								SeriesPublishStep.CREATE,
							);
							if (syncSuccess) {
								mutate({ publishStep: SeriesPublishStep.CREATE });
							}
						}
					},
				};
		}
	};

	const getMiddleButton = (publishStep: SeriesPublishStep): ButtonConfig => {
		switch (publishStep) {
			case SeriesPublishStep.CREATE:
			case SeriesPublishStep.PUBLISH:
				return {
					text: "Set to Coming Soon",
					onClick: async () => {
						setIsUpdating(true);
						try {
							const syncSuccess = await syncMobileSeriesToEditor(
								series,
								SeriesPublishStep.COMING_SOON,
							);
							if (syncSuccess) {
								await mutate({ publishStep: SeriesPublishStep.COMING_SOON });
							}
						} finally {
							setIsUpdating(false);
						}
					},
				};
			case SeriesPublishStep.COMING_SOON:
				return {
					text: "Update",
					onClick: async () => {
						setIsUpdating(true);
						try {
							const syncSuccess = await syncMobileSeriesToEditor(
								series,
								SeriesPublishStep.COMING_SOON,
							);
							if (syncSuccess) {
								// Add any additional actions after update if needed
								toast.success("Series updated successfully");
							}
						} finally {
							setIsUpdating(false);
						}
					},
				};
		}
	};

	const getRightButton = (publishStep: SeriesPublishStep): ButtonConfig => {
		switch (publishStep) {
			case SeriesPublishStep.CREATE:
			case SeriesPublishStep.COMING_SOON:
				return {
					text: "Publish",
					onClick: async () => {
						setIsUpdating(true);
						try {
							const syncSuccess = await syncMobileSeriesToEditor(
								series,
								SeriesPublishStep.PUBLISH,
							);
							if (syncSuccess) {
								await mutate({ publishStep: SeriesPublishStep.PUBLISH });
							}
						} finally {
							setIsUpdating(false);
						}
					},
				};
			case SeriesPublishStep.PUBLISH:
				return {
					text: "Update",
					onClick: async () => {
						setIsUpdating(true);
						try {
							const syncSuccess = await syncMobileSeriesToEditor(
								series,
								SeriesPublishStep.PUBLISH,
							);
							if (syncSuccess) {
								// Add any additional actions after update if needed
								toast.success("Series updated successfully");
							}
						} finally {
							setIsUpdating(false);
						}
					},
				};
		}
	};

	const uploadImage = async (
		file: File,
		folder: string,
		mutateKey: "coverURL" | "bannerURL" | "fullScreenCoverURL",
	) => {
		if (!file.type.startsWith("image")) {
			alert("File is not an image!");
			return;
		}
		const fileName = slugify(file.name, { strict: true });
		const storageRef = ref(
			storage,
			`${folder}/${new Date().getTime()}-${userId}-${fileName}`,
		);
		const snapshot = await uploadBytes(storageRef, file);
		const url = await getDownloadURL(snapshot.ref);
		mutate({ [mutateKey]: url });
	};

	const handleSyncMobileSeriesDataToForm = async (
		data: Partial<MobileSeries>,
	) => {
		const comicsEpisodeDoc = doc(
			collection(mobileDatabase, MOBILE_LIBRARY_COLLECTION),
			series.id,
		);
		const docSnapshot = await getDoc(comicsEpisodeDoc);
		if (!docSnapshot.exists()) {
			return;
		}
		setDoc(
			comicsEpisodeDoc,
			{
				ongoing: data.ongoing,
				release_status: data.releaseDay,
				episode_price: data.episode_price,
			},
			{ merge: true },
		);
	};

	return (
		<div className="flex flex-col w-full items-start text-center mt-[30px]">
			<SeriesUserAddModal
				currentUserIds={creatorIds}
				isOpen={creatorModalOpen}
				seriesId={id}
				setModalOpen={setCreatorModalOpen}
				type="Creators"
				addUser={async (userId: string) => {
					await mutate({ creatorIds: [...creatorIds, userId] });
				}}
			/>

			<SeriesUserAddModal
				currentUserIds={collaboratorIds as string[]}
				isOpen={collaboratorModalOpen}
				seriesId={id}
				setModalOpen={setCollaboratorModalOpen}
				type="Collaborators"
				addUser={async (userId: string) => {
					await mutate({ collaboratorIds: [...collaboratorIds, userId] });
				}}
			/>
			<div>
				<Button
					variant="ghost"
					className="text-lg text-gray-400"
					onClick={() => {
						router.push(SERIES_PATH);
					}}
				>
					My Series
				</Button>
				<ArrowForwardIosRoundedIcon
					className="text-[#8b898c]"
					style={{
						height: "0.5em",
					}}
				/>
				<Button variant="ghost" className="text-xl">
					{name}
				</Button>
			</div>
			<div
				onClick={() => {
					setIsSectionExpanded(!isSectionExpanded);
				}}
				className="cursor-pointer flex items-center justify-between mt-4"
			>
				<span
					className={`text-xl ${
						isSectionExpanded ? "text-white" : "text-neutral-500"
					} hover:text-white`}
				>
					Series Details
				</span>
				{isSectionExpanded ? (
					<ExpandLessIcon className="text-white" />
				) : (
					<ExpandMoreIcon className="text-neutral-500" />
				)}
			</div>

			<div
				className={`flex flex-col transition-all duration-500 ease-in-out overflow-hidden ${
					isSectionExpanded ? "max-h-fit" : "max-h-0"
				}`}
			>
				<div className="flex gap-4 w-full">
					<div className="text-left shrink-0">
						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Cover
						</div>
						<div className="text-[12px] text-[#8b898c] font-medium">
							Aspect ratio: 3:4
						</div>
						<button
							onClick={() => {
								document.getElementById("coverInput")?.click();
							}}
						>
							<input
								className="hidden"
								id="coverInput"
								name="cover"
								onChange={(event) => {
									const file = event.target.files?.[0];
									if (file) uploadImage(file, "series_thumbnails", "coverURL");
								}}
								type="file"
							/>

							<div className="relative">
								<div className="absolute top-0 left-0 right-0 h-5 bg-red-500 opacity-30 flex items-center justify-center">
									<span className="text-xs text-white">
										No text or faces in this margin
									</span>
								</div>
								<Image
									alt="cover"
									className={`h-[400px] w-[300px] ${
										coverURL ? "object-cover" : "object-contain"
									}`}
									height={400}
									src={coverURL || "/images/placeholderHumbnail.svg"}
									width={300}
								/>
								<div className="absolute bottom-0 left-0 right-0 h-5 bg-red-500 opacity-30 flex items-center justify-center">
									<span className="text-xs text-white">
										No text or faces in this margin
									</span>
								</div>
							</div>
						</button>

						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Banner
						</div>
						<div className="text-[12px] text-[#8b898c] font-medium">
							Aspect ratio: 39:35
						</div>
						<button
							onClick={() => {
								document.getElementById("bannerInput")?.click();
							}}
						>
							<input
								className="hidden"
								id="bannerInput"
								name="banner"
								onChange={(event) => {
									const file = event.target.files?.[0];
									if (file) uploadImage(file, "series_banners", "bannerURL");
								}}
								type="file"
							/>

							<div className="relative">
								<div className="absolute top-0 left-0 right-0 h-[30px] bg-red-500 opacity-30 flex items-center justify-center">
									<span className="text-xs text-white">
										No text or faces in this margin
									</span>
								</div>
								<Image
									alt="cover"
									className={`h-[269px] w-[300px] ${
										bannerURL ? "object-cover" : "object-contain"
									}`}
									height={269}
									src={bannerURL || "/images/placeholderHumbnail.svg"}
									width={300}
								/>
								<div className="absolute bottom-0 left-0 right-0 h-[30px] bg-red-500 opacity-30 flex items-center justify-center">
									<span className="text-xs text-white">
										No text or faces in this margin
									</span>
								</div>
							</div>
						</button>
					</div>

					<div className="text-left shrink-0">
						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Full Screen Cover
						</div>
						<div className="text-[12px] text-[#8b898c] font-medium">
							Aspect ratio: 9:19
						</div>
						<button
							onClick={() => {
								document.getElementById("fullScreenCoverInput")?.click();
							}}
						>
							<input
								className="hidden"
								id="fullScreenCoverInput"
								name="full screen cover"
								onChange={(event) => {
									const file = event.target.files?.[0];
									if (file)
										uploadImage(
											file,
											"series_full_screen_covers",
											"fullScreenCoverURL",
										);
								}}
								type="file"
							/>
							<div className="relative">
								<div className="absolute top-0 left-0 right-0 h-[32px] bg-red-500 opacity-30 flex items-center justify-center">
									<span className="text-xs text-white">
										No text or faces in this margin
									</span>
								</div>
								<Image
									alt="cover"
									className={`h-[633px] w-[300px] ${
										fullScreenCoverURL ? "object-cover" : "object-contain"
									}`}
									height={633}
									src={fullScreenCoverURL || "/images/placeholderHumbnail.svg"}
									width={300}
								/>
								<div className="absolute bottom-0 left-0 right-0 h-[20px] bg-red-500 opacity-30 flex items-center justify-center">
									<span className="text-xs text-white">
										No text or characters in this margin
									</span>
								</div>
							</div>
						</button>
					</div>

					<div className="text-left grow">
						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Name
						</div>
						<Input
							className="p-[16px] mt-[12px] w-full"
							name="name"
							onChange={(e) => {
								mutate({
									name: e.target.value.replace(/\n/g, ""),
								});
							}}
							placeholder="Enter the series name"
							type="text"
							defaultValue={name}
						/>

						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Tagline
						</div>
						<Input
							className="p-[16px] mt-[12px] w-full"
							name="tagline"
							onChange={(e) => {
								mutate({
									tagline: e.target.value.replace(/\n/g, ""),
								});
							}}
							placeholder="Enter the series tagline"
							type="text"
							defaultValue={tagline}
						/>

						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Description
						</div>
						<Textarea
							rows={4}
							name="description"
							onChange={(e) => {
								mutate({
									description: e.target.value,
								});
							}}
							placeholder="Enter the series description"
							defaultValue={description}
						/>

						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Tags
						</div>
						<div className="flex gap-2 flex-wrap mt-[12px]">
							{tags.map((tag) => {
								return (
									<Button
										variant="default"
										className="flex items-center p-[12px] text-[14px] text-white whitespace-nowrap hover:bg-[#3d3d3d] cursor-pointer"
										key={tag + Math.random().toString(36).substring(2, 15)}
										onClick={() => {
											mutate({
												tags: tags.filter((t) => t !== tag),
											});
										}}
									>
										{tag}
										<RemoveCircleOutlineRoundedIcon className="ml-1 text-red-300" />
									</Button>
								);
							})}
						</div>

						<div
							className="text-[14px] text-[#8b898c] font-medium mt-[24px] cursor-pointer"
							onClick={() => setAddTagsOpen(!addTagsOpen)}
						>
							Add Tags
							{addTagsOpen ? (
								<ExpandLessRoundedIcon />
							) : (
								<ExpandMoreRoundedIcon />
							)}
						</div>
						<div
							className={`flex gap-2 flex-wrap overflow-hidden mt-[12px] ${
								addTagsOpen ? "max-h-[100%]" : "max-h-0"
							}`}
						>
							{allTags
								?.filter((tag) => !tags.includes(tag.name))
								.map((tag) => {
									return (
										<Button
											variant="default"
											className="flex items-center p-[12px] text-[14px] text-white whitespace-nowrap hover:bg-[#3d3d3d]"
											key={tag.id + tag.name}
											onClick={() => {
												mutate({
													tags: [...tags, tag.name],
												});
											}}
										>
											{tag.name}
											<AddCircleOutlineRoundedIcon className="ml-1 text-green-300" />
										</Button>
									);
								})}
						</div>

						<div className="flex flex-row gap-4">
							<div className="flex flex-col w-24">
								<div className="text-[14px] text-[#8b898c] font-medium">
									Free Episodes
								</div>
								<Input
									className="p-[12px] text-[16px] mt-[12px] w-full"
									min={0}
									onChange={(e) => {
										const num = Math.trunc(Number(e.target.value));
										if (num < 0) return;
										setFreeEpisodesCount(num);
									}}
									step={1}
									type="number"
									defaultValue={freeEpisodesCount}
								/>
							</div>
							<div className="flex flex-col w-24">
								<div className="text-[14px] text-[#8b898c] font-medium">
									Episode Price
								</div>
								<Input
									type="number"
									defaultValue={series.episodePrice || 30}
									onChange={(e) =>
										mutate({
											episodePrice: Number.parseFloat(e.target.value),
										}).then(() =>
											handleSyncMobileSeriesDataToForm({
												episode_price: Number.parseFloat(e.target.value),
											}),
										)
									}
									className="p-[12px] text-[16px] mt-[12px] w-full"
								/>
							</div>
						</div>

						{(user?.uid === userId || creatorIds.includes(user?.uid ?? "")) && (
							<>
								<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
									Creators
								</div>
								<div className="flex gap-2 flex-wrap mt-[12px]">
									{(creatorIds ?? []).map((creatorId) => {
										const creator = users?.find(
											(user) => user.uid === creatorId,
										);
										if (!creator) return;

										return (
											<Button
												variant="ghost"
												className="flex items-center bg-transparent rounded-[16px] p-[12px] text-[14px] text-white whitespace-nowrap hover:bg-[#3d3d3d] cursor-pointer"
												key={creatorId}
												onClick={() => {
													mutate({
														creatorIds: (creatorIds ?? []).filter(
															(id) => id !== creatorId,
														),
													});
												}}
											>
												{creator.email}
												<RemoveCircleOutlineRoundedIcon className="ml-1 text-red-300" />
											</Button>
										);
									})}
								</div>
							</>
						)}

						<Button
							variant="default"
							onClick={() => {
								setCreatorModalOpen(true);
							}}
						>
							Add Creator
						</Button>

						<div className="text-[14px] text-[#8b898c] font-medium mt-[24px]">
							Editors
						</div>
						<div className="flex gap-2 flex-wrap mt-[12px]">
							{(collaboratorIds ?? []).map((collaboratorId) => {
								const collaborator = users?.find(
									(user) => user.uid === collaboratorId,
								);
								if (!collaborator) return;
								return (
									<Button
										variant="default"
										className="flex items-center p-[12px] text-[14px] text-white whitespace-nowrap hover:bg-[#3d3d3d] cursor-pointer"
										key={collaboratorId}
										onClick={() => {
											mutate({
												collaboratorIds: (collaboratorIds ?? []).filter(
													(id) => id !== collaboratorId,
												),
											});
										}}
									>
										{collaborator.email}
										<RemoveCircleOutlineRoundedIcon className="ml-1 text-red-300" />
									</Button>
								);
							})}
						</div>

						<Button
							variant="default"
							onClick={() => {
								setCollaboratorModalOpen(true);
							}}
						>
							Add Editor
						</Button>
						<div className="flex flex-row gap-4 mt-[24px]">
							<div className="flex flex-col">
								<div className="text-[14px] text-[#8b898c] font-medium">
									Ongoing
								</div>
								<input
									type="checkbox"
									checked={series.ongoing}
									onChange={(e) =>
										mutate({ ongoing: e.target.checked }).then(() =>
											handleSyncMobileSeriesDataToForm({
												ongoing: e.target.checked,
											}),
										)
									}
									className="mt-[12px] w-8 h-8"
								/>
							</div>

							<div className="flex flex-col">
								<div className="text-[14px] text-[#8b898c] font-medium">
									Release Day
								</div>
								<Input
									type="text"
									defaultValue={series.releaseDay || ""}
									onChange={(e) =>
										mutate({ releaseDay: e.target.value }).then(() =>
											handleSyncMobileSeriesDataToForm({
												releaseDay: e.target.value,
											}),
										)
									}
									className="p-[16px] mt-[12px] w-full"
								/>
							</div>
							<div className="flex flex-col">
								<div className="text-[14px] text-[#8b898c] font-medium">
									Coming Soon Date
								</div>
								<Input
									type="date"
									disabled={publishStep !== SeriesPublishStep.COMING_SOON}
									defaultValue={
										series.comingSoonDate
											? series.comingSoonDate
													.toDate()
													.toISOString()
													.split("T")[0]
											: ""
									}
									onChange={(e) =>
										mutate({
											comingSoonDate: e.target.value
												? Timestamp.fromDate(new Date(e.target.value))
												: undefined,
										}).then(() =>
											handleSyncMobileSeriesDataToForm({
												coming_soon_date: e.target.value
													? Timestamp.fromDate(new Date(e.target.value))
													: undefined,
											}),
										)
									}
									className={`p-[16px] mt-[12px] w-full ${publishStep !== SeriesPublishStep.COMING_SOON ? "text-neutral-600" : ""}`}
								/>
							</div>
						</div>
					</div>
				</div>

				<ThemeProvider
					theme={createTheme({
						palette: {
							mode: "dark",
						},
						typography: {
							fontFamily: "arial",
						},
					})}
				>
					<Stepper
						className="w-full mt-16 mb-4"
						activeStep={seriesPublishSteps.indexOf(publishStep)}
					>
						{seriesPublishSteps.map((label) => {
							return (
								<Step key={label}>
									<StepLabel className="font-sans">{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				</ThemeProvider>

				<div className="flex w-full justify-between mb-4">
					{[getLeftButton, getMiddleButton, getRightButton].map(
						(getButton, index) => {
							const config = getButton(publishStep as SeriesPublishStep);
							return (
								<Button
									key={config.text}
									variant="secondary"
									size="lg"
									className={`flex items-center justify-center text-[16px] font-semibold ${
										config.className || "text-black bg-white hover:bg-[#BFBFBF]"
									}`}
									onClick={config.onClick}
									disabled={isUpdating}
								>
									{isUpdating && index > 0 ? (
										<CircularProgress size={24} color="inherit" />
									) : (
										config.text
									)}
								</Button>
							);
						},
					)}
				</div>

				<div className="flex flex-row justify-between w-full mt-16 mb-4">
					<div className="flex flex-row space-x-8">
						<div className="flex flex-col">
							{updatedAt && (
								<div className="text-[12px] text-[#8b898c] font-medium">
									Last updated: {updatedAt.toDate().toLocaleString()}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SeriesForm;
