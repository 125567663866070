import type {
  ComicType,
  PanelType,
  TextBubbleType,
} from "@/src/constants/types/editorTypes";
import { signInAnonymously } from "firebase/auth";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  startAfter,
} from "firebase/firestore";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { fetchIsComicPublished } from "./desktopMobileSeriesOperations";
import editorDatabase, {
  SAVED_COMICS_COLLECTION,
  authClient
} from "./firebaseClientConfig";
import { fetchUserEmailById } from "./userOperations";
import { ComicFolderType, ComicPreviewItem } from "@/src/constants/types/projectsTypes";
import { extractCharactersFromPanels } from "../generationUtils";
import { DEFAULT_PAGE_HEIGHT } from "@/src/constants/editorConstants";

export const createComic = async (userId: string, comicName: string): Promise<string> => {
  const comicId = uuidv4();
  const comic: ComicType = {
    width: 600,
    height: DEFAULT_PAGE_HEIGHT,
    comicId: comicId,
    regions: [],
    bubbles: [],
    style: "painting",
    backColor: "#ffffff",
    userId: userId,
    comicName: comicName,
  };

  const comicRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  await setDoc(comicRef, {
    regions: comic.regions,
    bubbles: comic.bubbles,
    width: comic.width,
    height: comic.height,
    userId: comic.userId,
    savedAt: new Date().getTime(),
    createdAt: new Date().getTime(),
    comicId: comicId,
    comicName: comicName,
    comicPrompt: comicName,
    backColor: comic.backColor,
    style: comic.style,
    characters: extractCharactersFromPanels(comic),
  });

  return comicId;
};


export const duplicateComic = async (comicId: string, userId: string) => {
  const comicDocRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  const comicSnapshot = await getDoc(comicDocRef);

  if (!comicSnapshot.exists()) {
    throw new Error("Comic does not exist!");
  }

  const comicData = comicSnapshot.data() as ComicType;
  const newComicId = uuidv4();

  const regionsCollectionRef = collection(
    editorDatabase,
    SAVED_COMICS_COLLECTION,
    comicId,
    "regions",
  );
  const newRegionsCollectionRef = collection(
    editorDatabase,
    SAVED_COMICS_COLLECTION,
    newComicId,
    "regions",
  );

  const bubblesCollectionRef = collection(
    editorDatabase,
    SAVED_COMICS_COLLECTION,
    comicId,
    "bubbles",
  );
  const newBubblesCollectionRef = collection(
    editorDatabase,
    SAVED_COMICS_COLLECTION,
    newComicId,
    "bubbles",
  );

  await setDoc(doc(editorDatabase, SAVED_COMICS_COLLECTION, newComicId), {
    ...comicData,
    comicId: newComicId,
    comicName: `${comicData.comicName} (Copy)`,
    userId,
    collaboratorIds: []
  });

  // Duplicate regions subcollection
  const regionsSnapshot = await getDocs(regionsCollectionRef);

  for (const regionDoc of regionsSnapshot.docs) {
    const newRegionId = uuidv4();
    await setDoc(doc(newRegionsCollectionRef, newRegionId), {
      ...regionDoc.data(),
      _id: newRegionId,
    });
  }

  // Duplicate bubbles subcollection
  const bubblesSnapshot = await getDocs(bubblesCollectionRef);

  for (const bubbleDoc of bubblesSnapshot.docs) {
    const newBubbleId = uuidv4();
    await setDoc(doc(newBubblesCollectionRef, newBubbleId), {
      ...bubbleDoc.data(),
      _id: newBubbleId,
    });
  }

  return newComicId;
};

export const deleteComic = async (comicId: string) => {
  const isComicPublished = await fetchIsComicPublished(comicId);
  if (isComicPublished) {
    throw new Error("Comic is published and cannot be deleted!");
  }

  const comicDocRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  const comicDoc = await getDoc(comicDocRef);
  const comicData = comicDoc.data();

  if (comicData?.folderId) {
    const folderRef = doc(editorDatabase, "folders", comicData.folderId);
    await updateDoc(folderRef, {
      comicIds: arrayRemove(comicId)
    });
  }

  await deleteDoc(comicDocRef);
};

export const updateComicName = async (comicId: string, value: string) => {
  const comicRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  await setDoc(
    comicRef,
    { comicName: value, comicPrompt: value },
    { merge: true },
  );
};

export const getComicNameById = async (
  comicId: string,
): Promise<string | null> => {
  const comicDocRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  const comicSnapshot = await getDoc(comicDocRef);

  if (!comicSnapshot.exists()) {
    return null;
  }

  const comicData = comicSnapshot.data();
  return comicData.comicName || null;
};

export const fetchComicsFromFirebase = async (
  UID: string,
  source: "collaborations" | "projects" | "recent",
  folder?: ComicFolderType | null,
  lastVisible?: any,
  pageSize = 40
): Promise<{ comics: ComicPreviewItem[], lastVisible: any }> => {
  const savedComicsCollection = collection(editorDatabase, SAVED_COMICS_COLLECTION);

  let q1 = query(savedComicsCollection, orderBy("savedAt", "desc"), limit(pageSize));

  if (lastVisible) {
    q1 = query(q1, startAfter(lastVisible));
  }

  if (folder) {
    if (folder.comicIds.length === 0) {
      return { comics: [], lastVisible: null };
    }
    q1 = query(q1, where("comicId", "in", folder.comicIds));
  } else if (source === "collaborations") {
    q1 = query(q1, where("collaboratorIds", "array-contains", UID));
  } else if (source === "projects") {
    q1 = query(q1, where("userId", "==", UID));
  } else if (source === "recent") {
    const userDocRef = doc(editorDatabase, "users", UID);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists() || !userDoc.data().recentlyOpenedComics || userDoc.data().recentlyOpenedComics.length === 0) {
      return { comics: [], lastVisible: null };
    }

    const recentlyOpenedComics = userDoc.data().recentlyOpenedComics;
    q1 = query(q1, where("comicId", "in", recentlyOpenedComics));
  }

  const queriedDocs = await getDocs(q1);
  const lastVisibleDoc = queriedDocs.docs[queriedDocs.docs.length - 1];

  const savedComics = await Promise.all(
    queriedDocs.docs.map(async (doc) => {
      let thumbnailUrl = "";

      const regionsCollectionRef = collection(doc.ref, "regions");
      const regionsQuery = query(regionsCollectionRef, limit(10));
      const regionsSnapshot = await getDocs(regionsQuery);

      if (!regionsSnapshot.empty) {
        const firstRegionWithImage = regionsSnapshot.docs.find(
          (doc) => doc.data().imgUrl,
        );
        thumbnailUrl = firstRegionWithImage?.data().imgUrl || "";
      }

      return {
        comicId: doc.get("comicId"),
        comicName: doc.get("comicName"),
        thumbnailUrl,
        savedAt: doc.get("savedAt"),
        folderId: doc.get("folderId"),
      };
    }),
  );

  return { comics: savedComics, lastVisible: lastVisibleDoc };
};

export const fetchUserFolders = async (UID: string): Promise<ComicFolderType[]> => {
  const foldersCollection = collection(editorDatabase, "folders");
  const q = query(foldersCollection, where("userIds", "array-contains", UID));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    folderName: doc.data().folderName,
    comicCount: doc.data().comicIds.length,
    comicIds: doc.data().comicIds,
    ownerId: doc.data().ownerId,
  }));
};

export const createFolder = async (folderName: string, UID: string) => {
  const id = uuidv4();
  const folderDocRef = doc(editorDatabase, "folders", id);
  await setDoc(folderDocRef, {
    id,
    folderName,
    userIds: [UID],
    comicIds: [],
    ownerId: UID,
  });
};

export const addComicToFolder = async (folderId: string, comicId: string) => {
  if (!folderId || !comicId) {
    throw new Error("Folder ID or comic ID is not valid!");
  }
  const folderDocRef = doc(editorDatabase, "folders", folderId);
  const comicDocRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);

  await updateDoc(comicDocRef, { folderId })
  await updateDoc(folderDocRef, {
    comicIds: arrayUnion(comicId),
  });
};

export const addUserToFolder = async (folderId: string, userId: string) => {
  const folderDocRef = doc(editorDatabase, "folders", folderId);
  await updateDoc(folderDocRef, {
    userIds: arrayUnion(userId),
  });
};

export const removeUserFromFolder = async (folderId: string, userId: string) => {
  const folderDocRef = doc(editorDatabase, "folders", folderId);
  await updateDoc(folderDocRef, {
    userIds: arrayRemove(userId),
  });
};

export const fetchFolderUsers = async (folderId: string): Promise<{ id: string; email: string }[]> => {
  const folderDocRef = doc(editorDatabase, "folders", folderId);
  const folderDoc = await getDoc(folderDocRef);

  if (!folderDoc.exists()) {
    throw new Error("Folder not found");
  }

  const userIds = folderDoc.data().userIds || [];
  const users = await Promise.all(
    userIds.map(async (userId: string) => {
      const email = await fetchUserEmailById(userId);
      return { id: userId, email: email || "Unknown" };
    })
  );

  return users;
};

export const removeComicFromFolder = async (folderId: string, comicId: string) => {
  const folderDocRef = doc(editorDatabase, "folders", folderId);
  await updateDoc(folderDocRef, {
    comicIds: arrayRemove(comicId),
  });
  const comicDocRef = doc(editorDatabase, SAVED_COMICS_COLLECTION, comicId);
  await updateDoc(comicDocRef, { folderId: null });
};

export const fetchComicAndConvertToObject = async (
  comicId: string,
): Promise<ComicType> => {
  await signInAnonymously(authClient);

  const comicDocRef = doc(editorDatabase, "comicsSave", comicId);
  const comicDoc = await getDoc(comicDocRef);
  const comicData = comicDoc.data();
  if (!comicData) {
    throw new Error("COMIC_NOT_FOUND");
  }

  const regionsCollectionRef = collection(comicDocRef, "regions");
  const bubblesCollectionRef = collection(comicDocRef, "bubbles");

  const [regionsSnapshot, bubblesSnapshot] = await Promise.all([
    getDocs(regionsCollectionRef),
    getDocs(bubblesCollectionRef),
  ]);

  let data: ComicType;

  // Data migration from regions and bubbles field to subcollections
  if (
    comicData.regions &&
    comicData.bubbles &&
    (regionsSnapshot.docs.length < comicData.regions.length ||
      bubblesSnapshot.docs.length < comicData.bubbles.length)
  ) {
    const batch = writeBatch(editorDatabase);

    // Save regions to subcollection
    for (const region of comicData.regions as PanelType[]) {
      const regionDocRef = doc(regionsCollectionRef, region._id);
      batch.set(regionDocRef, region);
    }

    // Save bubbles to subcollection
    for (const bubble of comicData.bubbles as TextBubbleType[]) {
      const bubbleDocRef = doc(bubblesCollectionRef, bubble._id);
      batch.set(bubbleDocRef, bubble);
    }

    // Backup regions and bubbles
    const backupRef = doc(editorDatabase, "comicsSaveRegionsBubblesBackup", comicId);
    batch.set(backupRef, {
      regions: comicData.regions || [],
      bubbles: comicData.bubbles || [],
    });

    // Remove regions and bubbles from main document
    batch.update(comicDocRef, {
      regions: deleteField(),
      bubbles: deleteField(),
    });

    await batch.commit();

    data = comicData as ComicType;
  } else {
    const regions = regionsSnapshot.docs.map((doc) => doc.data());
    const bubbles = bubblesSnapshot.docs.map((doc) => doc.data());
    data = { ...comicData, regions, bubbles } as ComicType;
  }

  // Remove controlnetscribblebase64image and mask fields from regions and variants
  data.regions = data.regions.map((region) => {
    // Remove from sdParameters
    if (region.sdParameters) {
      const { controlnetscribblebase64image, mask, ...restSdParams } =
        region.sdParameters as any;
      region.sdParameters = restSdParams;
    }

    // Remove from variants
    if (region.variants) {
      region.variants = region.variants.map((variant) => {
        const { controlnetscribblebase64image, mask, ...restVariant } =
          variant as any;
        return restVariant;
      });
    }

    return region;
  });

  return data;
};

export const debouncedSaveComic = debounce(
  async (
    comicId: string,
    { regions, bubbles, ...rest }: Partial<ComicType>,
    setIsSaving,
    setIsSavingFailed,
    regionsToDelete: string[],
    bubblesToDelete: string[],
  ) => {
    try {
      const comicRef = doc(editorDatabase, "comicsSave", comicId);
      const batch = writeBatch(editorDatabase);

      // Update or create main comic document
      batch.set(
        comicRef,
        { ...rest, savedAt: new Date().getTime(), comicId },
        { merge: true },
      );

      const regionsRef = collection(comicRef, "regions");
      const bubblesRef = collection(comicRef, "bubbles");

      // Update or create regions and bubbles
      for (const region of regions ?? []) {
        batch.set(doc(regionsRef, region._id), region, { merge: true });
      }
      for (const bubble of bubbles ?? []) {
        batch.set(doc(bubblesRef, bubble._id), bubble, { merge: true });
      }

      // Delete regions and bubbles
      for (const id of regionsToDelete ?? []) {
        batch.delete(doc(regionsRef, id));
      }
      for (const id of bubblesToDelete ?? []) {
        batch.delete(doc(bubblesRef, id));
      }

      await batch.commit();

      setIsSaving(false);
    } catch (err) {
      console.error(`Error saving comic: ${err}`);
      alert("Failed to save comic. Please try again.");
      setIsSaving(false);
      setIsSavingFailed(true);
    }
  },
  1000,
  { leading: false, trailing: true },
);

export const fetchComicUserIdAndCollaboratorIds = async (comicId: string) => {
  const comicDocRef = doc(editorDatabase, "comicsSave", comicId);
  const comicDoc = await getDoc(comicDocRef);
  return [comicDoc.get("userId"), ...(comicDoc.get("collaboratorIds") ?? [])];
};
