"use client";
import BaseModal from "@/components/ui/custom/BaseModal";
import { searchUsers } from "@/src/utils/collaboratorUtils";
import type { User } from "@/src/utils/firebase/userOperations";
import React from "react";

type seriesCollaboratorModalProps = {
	addUser: (userId: string) => Promise<void>;
	currentUserIds: string[];
	isOpen: boolean;
	seriesId: string;
	setModalOpen: (modalOpen: boolean) => void;
	type: "Creators" | "Collaborators";
};

const SeriesUserAddModal = ({
	addUser,
	currentUserIds,
	isOpen,
	seriesId,
	setModalOpen,
	type,
}: seriesCollaboratorModalProps) => {
	const [searchValue, setSearchValue] = React.useState("");
	const [searchResults, setSearchResults] = React.useState<User[]>([]);

	const typename = type === "Creators" ? "Creator" : "Editor";

	const initiateSearch = async () => {
		const matches = await searchUsers(searchValue);
		if (!matches.length) {
			alert("No user found");
		}
		setSearchResults(matches);
	};

	const handleAddUser = async (userId: string) => {
		await addUser(userId);
		setModalOpen(false);
	};

	if (!isOpen) return null;

	return (
		<BaseModal
			isOpen={isOpen}
			onClose={() => setModalOpen(false)}
			title={`Add ${typename}`}
		>
			<div className="flex flex-col gap-4 p-8 text-white">
				<div className="flex w-full gap-4 mt-8">
					<input
						className="flex-grow items-center rounded-full border border-[#1f1f1f] px-4 py-2 bg-black text-white"
						onChange={(e) => setSearchValue(e.target.value)}
						placeholder="Search"
						value={searchValue}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								initiateSearch();
							}
						}}
					/>

					<button
						className="flex items-center justify-center rounded-full px-4 py-2 text-black bg-white text-[16px] font-semibold"
						onClick={initiateSearch}
					>
						Search
					</button>
				</div>

				{searchResults
					.filter((user) => !currentUserIds.includes(user.uid))
					.map((user) => (
						<div className="flex items-center gap-4" key={user.uid}>
							<div className="text-[16px] font-semibold text-white">
								{user.email}
							</div>
							<button
								className="flex items-center justify-center rounded-full px-4 py-2 text-black bg-white text-[16px] font-semibold"
								onClick={() => handleAddUser(user.uid)}
							>
								Add {typename}
							</button>
						</div>
					))}
			</div>
		</BaseModal>
	);
};

export default SeriesUserAddModal;
