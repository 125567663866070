// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: `${process.env.NEXT_PUBLIC_API_KEY_COMIC}`,
  authDomain: `${process.env.NEXT_PUBLIC_AUTH_DOMAIN_COMIC}`,
  projectId: `${process.env.NEXT_PUBLIC_PROJECT_ID_COMIC}`,
  storageBucket: `${process.env.NEXT_PUBLIC_STORAGE_BUCKET_COMIC}`,
  messagingSenderId: `${process.env.NEXT_PUBLIC_MESSAGING_SENDING_ID_COMIC}`,
  appId: `${process.env.NEXT_PUBLIC_APP_ID_COMIC}`,
  measurementId: `${process.env.NEXT_PUBLIC_MEASUREMENT_ID_COMIC}`,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "shortbread-comics");

const mobileDatabase = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

export default mobileDatabase;

export const AUTHORS_COLLECTION = "authors";
export const MOBILE_LIBRARY_COLLECTION = "comics_library";
export const MOBILE_EPISODES_COLLECTION = "comics_episodes";
export const MOBILE_COMING_SOON_LIBRARY = "coming_soon_library";
